define("mixpanel/services/mixpanel", ["exports", "@ember/service", "mixpanel/configuration"], function (_exports, _service, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MixpanelService extends _service.default {
    pageHasAnalytics() {
      return window.mixpanel && typeof window.mixpanel === 'object' && _configuration.default.enabled;
    }
    logTrackingEnabled() {
      return !!_configuration.default && !!_configuration.default.LOG_EVENT_TRACKING;
    }
    logTracking() {
      console.log('[Mixpanel] ', arguments);
    }
    trackPageView(page, overrides = {}) {
      if (this.pageHasAnalytics()) {
        if (!page) {
          const loc = window.location;
          page = loc.hash ? loc.hash.substring(1) : loc.pathname + loc.search;
        }
        window.mixpanel.track('visit', {
          pageName: page,
          ...overrides
        });
      }
      if (this.logTrackingEnabled()) {
        this.logTracking('page view', page);
      }
    }
    trackEvent(event, properties, options, callback) {
      if (this.pageHasAnalytics()) {
        window.mixpanel.track(event, properties, options, callback);
      }
      if (this.logTrackingEnabled()) {
        this.logTracking(event, properties, options);
      }
    }
    identify(userId, traits, options, callback) {
      if (this.pageHasAnalytics()) {
        window.mixpanel.identify(userId, traits, options, callback);
      }
      if (this.logTrackingEnabled()) {
        this.logTracking('identify user', userId, traits, options);
      }
    }
    alias(userId, previousId, options, callback) {
      if (this.pageHasAnalytics()) {
        window.mixpanel.alias(userId, previousId, options, callback);
      }
      if (this.logTrackingEnabled()) {
        this.logTracking('alias user', userId, previousId, options);
      }
    }
    register(props, defaults, days) {
      if (this.pageHasAnalytics()) {
        window.mixpanel.register(props, defaults, days);
      }
      if (this.logTrackingEnabled()) {
        this.logTracking('register', props, defaults);
      }
    }
    registerOnce(props, defaults, days) {
      if (this.pageHasAnalytics()) {
        window.mixpanel.register_once(props, defaults, days);
      }
      if (this.logTrackingEnabled()) {
        this.logTracking('register_once', props, defaults);
      }
    }
    reset(traits, options, callback) {
      if (this.pageHasAnalytics()) {
        window.mixpanel.reset(traits, options, callback);
      }
      if (this.logTrackingEnabled()) {
        this.logTracking('reset', traits, options);
      }
    }
    peopleSet(attributes) {
      if (this.pageHasAnalytics()) {
        window.mixpanel.people.set(attributes);
      }
      if (this.logTrackingEnabled()) {
        this.logTracking('people.set', attributes);
      }
    }
    peopleSetOnce(attributes) {
      if (this.pageHasAnalytics()) {
        window.mixpanel.people.set_once(attributes);
      }
      if (this.logTrackingEnabled()) {
        this.logTracking('people.set_once', attributes);
      }
    }
  }
  _exports.default = MixpanelService;
});