define("ember-could-get-used-to-this/-private/resources", ["exports", "@ember/helper", "@glimmer/tracking/primitives/cache", "@ember/application", "@ember/destroyable"], function (_exports, _helper, _cache, _application, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Resource = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Resource {
    constructor(ownerOrThunk, args) {
      if (typeof ownerOrThunk === 'function') {
        return {
          definition: this.constructor,
          args: ownerOrThunk
        };
      }
      (0, _application.setOwner)(this, ownerOrThunk);
      this.args = args;
    }
    setup() {}
  }
  _exports.Resource = Resource;
  class ResourceManager {
    constructor(owner) {
      _defineProperty(this, "capabilities", (0, _helper.capabilities)('3.23', {
        hasValue: true,
        hasDestroyable: true
      }));
      this.owner = owner;
    }
    createHelper(Class, args) {
      let {
        update,
        teardown
      } = Class.prototype;
      let hasUpdate = typeof update === 'function';
      let hasTeardown = typeof teardown === 'function';
      let owner = this.owner;
      let instance;
      let cache;
      if (hasUpdate) {
        cache = (0, _cache.createCache)(() => {
          if (instance === undefined) {
            instance = setupInstance(cache, Class, owner, args, hasTeardown);
          } else {
            instance.update();
          }
          return instance;
        });
      } else {
        cache = (0, _cache.createCache)(() => {
          if (instance !== undefined) {
            (0, _destroyable.destroy)(instance);
          }
          instance = setupInstance(cache, Class, owner, args, hasTeardown);
          return instance;
        });
      }
      return cache;
    }
    getValue(cache) {
      let instance = (0, _cache.getValue)(cache);
      return instance.value;
    }
    getDestroyable(cache) {
      return cache;
    }
    getDebugName(fn) {
      return fn.name || '(anonymous function)';
    }
  }
  function setupInstance(cache, Class, owner, args, hasTeardown) {
    let instance = new Class(owner, args);
    (0, _destroyable.associateDestroyableChild)(cache, instance);
    instance.setup();
    if (hasTeardown) {
      (0, _destroyable.registerDestructor)(instance, () => instance.teardown());
    }
    return instance;
  }
  (0, _helper.setHelperManager)(owner => new ResourceManager(owner), Resource);
});