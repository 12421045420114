define("mixpanel/instance-initializers/mixpanel", ["exports", "mixpanel/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(instance) {
    if (!_configuration.default.enabled || !_configuration.default.autoPageviewTracking) {
      return;
    }
    const router = instance.lookup('service:router');
    router.on('didTransition', function () {
      const attributeOverrides = _configuration.default.attributeOverrides;
      const mixpanelService = instance.lookup('service:mixpanel');
      const pageViewAttribute = _configuration.default.pageViewAttribute;
      mixpanelService.trackPageView(this.get(pageViewAttribute), attributeOverrides);
    });
  }
  var _default = _exports.default = {
    name: 'mixpanel',
    initialize
  };
});